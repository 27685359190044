import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      title: "Our Story",
      metaTags: [
        {
          name: "description",
          content: "Learn more about our love story.",
        },
        {
          property: "og:description",
          content: "Learn more about our love story.",
        },
      ],
    },
  },
  {
    path: "/wedding-party",
    name: "wedding-party",
    component: function () {
      return import(/* webpackChunkName: "rsvp" */ "@/views/WeddingParty.vue");
    },
    meta: {
      title: "Wedding Party",
      metaTags: [
        {
          name: "description",
          content: "Learn more about our wedding party.",
        },
        {
          property: "og:description",
          content: "Learn more about our wedding party.",
        },
      ],
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: function () {
      return import(
        /* webpackChunkName: "policy" */ "@/views/PrivacyPolicy.vue"
      );
    },
    meta: {
      title: "Privacy Policy",
      metaTags: [
        {
          name: "description",
          content: "Review our privacy policy.",
        },
        {
          property: "og:description",
          content: "Review our privacy policy.",
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: function () {
      return import(/* webpackChunkName: "faq" */ "@/views/FAQPage.vue");
    },
    meta: {
      title: `FAQ's`,
      metaTags: [
        {
          name: "description",
          content: "Learn about frequently asked questions.",
        },
        {
          property: "og:description",
          content: "Learn about frequently asked questions.",
        },
      ],
    },
  },
  {
    path: "/travel",
    name: "travel",
    component: function () {
      return import(/* webpackChunkName: "travel" */ "@/views/TravelPage.vue");
    },
    meta: {
      title: `Travel Information`,
      metaTags: [
        {
          name: "description",
          content: "Learn about travel.",
        },
        {
          property: "og:description",
          content: "Learn about travel.",
        },
      ],
    },
  },
  {
    path: "/rsvp",
    name: "rsvp",
    component: function () {
      return import(/* webpackChunkName: "rsvp" */ "@/views/RSVPPage.vue");
    },
    meta: {
      title: `RSVP`,
      metaTags: [
        {
          name: "description",
          content: "Register/sign up for the event.",
        },
        {
          property: "og:description",
          content: "Register/sign up for the event.",
        },
      ],
    },
  },
  {
    path: "/details",
    name: "details",
    component: function () {
      return import(
        /* webpackChunkName: "details" */ "@/views/EventDetailsPage.vue"
      );
    },
    meta: {
      title: `Details`,
      metaTags: [
        {
          name: "description",
          content: "Learn about wedding details.",
        },
        {
          property: "og:description",
          content: "Learn about wedding details.",
        },
      ],
    },
  },
  {
    path: "/registry",
    name: "registry",
    component: function () {
      return import(
        /* webpackChunkName: "registry" */ "@/views/RegistryPage.vue"
      );
    },
    meta: {
      title: `Gift Registry`,
      metaTags: [
        {
          name: "description",
          content: "Find our gift registry here.",
        },
        {
          property: "og:description",
          content: "Find our gift registry here.",
        },
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  // Update the document title
  if (to.meta.title) {
    document.title = `The Applegaths | ${to.meta.title}`;
  }

  // Remove any stale meta tags
  // const staleMetaTags = document.querySelectorAll(
  //   "meta[data-vue-router-controlled]"
  // );
  // staleMetaTags.forEach((tag) => tag.parentNode.removeChild(tag));

  // Add new meta tags
  // if (to.meta.metaTags) {
  //   to.meta.metaTags.forEach((tagDef) => {
  //     const tag = document.createElement("meta");
  //     Object.keys(tagDef).forEach((key) => {
  //       tag.setAttribute(key, tagDef[key]);
  //     });
  //     tag.setAttribute("data-vue-router-controlled", "");
  //     document.head.appendChild(tag);
  //   });
  // }

  next();
});

export default router;
