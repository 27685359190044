<template>
  <header class="py-6 shadow-md w-full flex justify-between md:block">
    <div
      class="w-full px-4 flex justify-between items-center md:justify-center md:align-center"
    >
      <!-- Hamburger Icon -->
      <button
        @click="toggleMenu"
        class="md:hidden text-gray-600 focus:outline-none"
        aria-label="Toggle Navigation Menu"
      >
        <svg
          v-if="!isMenuOpen"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
        <svg
          v-if="isMenuOpen"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>

      <!-- SVG Logo -->
      <router-link to="/">
        <div class="svg-container" v-if="!isMenuOpen">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="150px"
            height="75px"
            viewBox="0 100 300 100"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
              fill="#FFFFFF"
              stroke="none"
            >
              <path
                d="M1455 2620 c-109 -43 -207 -146 -385 -404 -47 -68 -110 -164 -140
-214 -30 -50 -57 -91 -60 -92 -3 0 -4 30 -2 68 3 63 1 71 -30 117 -84 123
-233 124 -345 2 -59 -64 -76 -102 -81 -184 -4 -64 -1 -79 22 -128 45 -91 122
-128 221 -106 30 7 58 17 61 23 5 8 1 9 -12 3 -10 -4 -47 -10 -81 -13 -54 -4
-68 -1 -106 22 -50 28 -77 75 -77 129 0 101 83 255 164 302 73 43 168 27 195
-32 20 -43 12 -122 -39 -374 -27 -137 -50 -257 -50 -267 0 -20 76 -112 92
-112 6 0 4 8 -5 18 -10 11 -17 35 -17 58 0 56 51 305 61 298 5 -3 9 -11 9 -18
0 -7 16 -45 35 -84 63 -125 172 -197 267 -177 88 19 180 97 213 181 15 40 13
38 -39 -43 -77 -119 -203 -128 -306 -22 -45 46 -84 118 -115 210 l-25 71 121
202 c211 350 296 454 430 526 80 42 146 47 181 12 36 -36 30 -130 -15 -255
-33 -90 -124 -263 -134 -254 -7 7 54 268 77 329 12 32 31 65 43 73 11 8 17 18
13 22 -23 23 -105 -92 -140 -198 -27 -83 -68 -274 -86 -406 -9 -68 -8 -88 4
-117 24 -57 70 -72 106 -34 23 25 133 238 144 278 4 14 -20 -29 -53 -95 -64
-128 -110 -193 -129 -181 -14 9 -16 98 -3 178 7 42 22 76 59 130 160 238 223
428 166 504 -51 69 -124 88 -209 54z m-602 -773 c2 -29 7 -61 11 -73 7 -19 4
-20 -24 -16 l-32 5 7 46 c5 25 9 60 9 78 2 51 23 20 29 -40z"
              />
              <path
                d="M2337 2375 c4 -37 -59 -350 -85 -415 -8 -19 -26 -31 -78 -48 -120
-41 -233 -102 -295 -159 -122 -113 -145 -250 -53 -322 136 -107 358 103 469
444 20 62 26 71 56 82 266 96 351 132 398 170 45 37 6 32 -50 -6 -30 -20 -84
-49 -120 -64 -80 -33 -253 -97 -255 -94 -1 1 13 63 32 137 44 173 45 233 5
280 l-29 35 5 -40z m-97 -465 c0 -22 -73 -207 -108 -274 -85 -163 -226 -255
-299 -195 -24 21 -16 108 17 178 48 100 141 187 270 250 112 54 120 57 120 41z"
              />
              <path
                d="M2138 2328 c2 -18 -8 -76 -22 -128 -34 -125 -34 -164 0 -195 15 -14
32 -25 39 -25 16 0 79 69 99 109 27 51 18 47 -21 -9 -21 -29 -48 -60 -60 -68
-38 -24 -38 6 1 145 l36 127 -28 35 c-37 46 -49 48 -44 9z"
              />
              <path
                d="M1917 2249 c-60 -52 -109 -151 -115 -228 -4 -50 -1 -61 23 -89 31
-38 74 -42 118 -14 43 29 96 106 111 162 4 14 -7 0 -23 -30 -36 -68 -87 -118
-127 -126 -28 -5 -32 -3 -38 21 -6 24 -2 73 11 118 3 11 14 17 31 17 69 0 142
72 142 140 0 78 -62 92 -133 29z m103 6 c0 -63 -83 -172 -124 -163 -21 4 42
137 79 166 36 28 45 28 45 -3z"
              />
              <path
                d="M1711 2224 c-29 -21 -60 -89 -61 -132 0 -19 11 -66 24 -105 37 -111
37 -149 2 -172 -52 -34 -104 13 -77 71 13 29 1 31 -24 4 -27 -29 -10 -70 37
-93 36 -18 38 -18 82 2 32 14 51 30 62 54 15 31 15 39 1 83 -8 27 -27 76 -41
109 -33 77 -34 124 -3 153 33 31 75 29 83 -3 7 -27 24 -34 24 -10 0 23 -37 55
-63 55 -12 0 -33 -7 -46 -16z"
              />
              <path
                d="M1223 2023 c-78 -54 -131 -140 -140 -231 -9 -82 31 -142 96 -142 22
0 76 52 113 109 40 64 35 76 -6 15 -34 -50 -89 -104 -106 -104 -17 0 -30 39
-30 89 0 47 2 51 23 51 27 0 94 45 125 85 11 14 26 42 33 62 10 31 9 39 -8 62
-27 36 -52 37 -100 4z m66 -72 c-20 -47 -97 -131 -120 -131 -31 0 36 145 88
190 35 31 37 32 46 13 6 -14 2 -35 -14 -72z"
              />
              <path
                d="M1454 1626 c-41 -41 -44 -77 -9 -111 34 -35 43 -31 38 18 -6 55 19
120 44 115 47 -9 21 -113 -39 -160 -23 -18 -64 -54 -90 -80 -43 -43 -48 -53
-48 -92 0 -70 61 -126 138 -126 48 0 129 47 155 91 21 35 25 38 37 24 20 -24
65 -45 97 -45 26 1 25 2 -11 15 -37 13 -106 70 -106 87 0 4 8 8 19 8 10 0 30
9 45 21 20 16 26 29 26 59 0 21 -4 41 -9 44 -5 4 -7 -11 -4 -33 5 -34 2 -41
-22 -57 -46 -30 -86 -23 -140 24 -26 23 -52 42 -56 42 -19 0 -7 16 27 38 43
28 67 78 51 107 -30 56 -93 61 -143 11z m105 -204 c56 -53 59 -62 35 -122 -25
-63 -98 -100 -140 -72 -33 22 -43 91 -22 153 15 45 46 89 63 89 7 0 36 -22 64
-48z"
              />
              <path
                d="M2340 1405 c17 -20 8 -83 -26 -183 -30 -90 -97 -236 -112 -246 -24
-15 -34 38 -33 171 1 133 1 134 -25 158 -36 33 -44 31 -38 -7 3 -18 1 -76 -4
-129 -7 -78 -15 -109 -43 -165 -19 -37 -39 -69 -46 -71 -21 -7 -26 64 -13 179
11 105 11 112 -8 137 -10 14 -30 28 -43 29 -19 3 -21 2 -11 -11 10 -11 10 -30
2 -78 -5 -35 -10 -98 -10 -139 0 -87 22 -135 66 -146 32 -8 52 14 86 95 l22
54 17 -41 c19 -44 57 -76 80 -67 32 12 129 179 175 302 32 85 31 126 -2 152
-31 24 -52 28 -34 6z"
              />
              <path
                d="M975 1324 c-94 -22 -242 -84 -333 -139 -96 -58 -181 -124 -182 -142
0 -25 49 -12 91 23 59 49 217 141 311 181 272 114 404 72 384 -122 -15 -143
-80 -318 -164 -441 -107 -157 -289 -275 -472 -306 -63 -11 -74 -10 -117 8 -90
37 -114 115 -74 243 46 148 157 254 309 296 37 10 41 13 19 14 -41 1 -134 -34
-195 -75 -163 -108 -242 -276 -183 -392 119 -234 537 -113 792 230 130 175
192 395 140 496 -32 62 -100 118 -160 131 -65 14 -91 13 -166 -5z"
              />
              <path
                d="M2700 1322 c0 -6 5 -14 10 -17 18 -11 11 -66 -16 -118 -36 -72 -83
-133 -156 -203 -89 -85 -154 -131 -298 -209 -189 -102 -271 -162 -428 -309
-156 -147 -209 -181 -285 -181 -43 0 -52 4 -79 34 -28 31 -30 39 -25 86 11
109 102 228 222 292 71 37 125 53 220 63 l70 7 -70 2 c-126 2 -283 -56 -380
-141 -56 -49 -105 -145 -105 -205 0 -55 20 -94 67 -130 31 -24 46 -28 97 -28
108 0 197 54 387 231 138 129 222 192 373 284 230 138 328 219 397 328 42 66
53 113 38 162 -12 39 -39 75 -39 52z"
              />
              <path
                d="M920 1193 c0 -3 7 -14 15 -23 13 -14 9 -43 -35 -260 -28 -134 -50
-252 -50 -262 0 -22 39 -78 54 -78 6 0 11 19 11 43 1 23 22 140 49 259 30 138
46 228 42 244 -11 40 -86 108 -86 77z"
              />
              <path
                d="M1733 1180 c-69 -42 -133 -161 -133 -248 0 -37 6 -50 34 -78 42 -42
69 -43 127 -5 44 30 120 132 133 181 4 14 -6 2 -22 -27 -40 -70 -66 -103 -109
-135 -68 -52 -105 -29 -95 60 3 30 9 42 20 42 33 0 104 44 127 78 45 65 27
152 -31 152 -10 0 -33 -9 -51 -20z m74 -42 c-3 -23 -18 -60 -34 -82 -28 -40
-77 -76 -88 -65 -8 9 30 93 65 144 41 59 65 60 57 3z"
              />
              <path
                d="M1509 1081 c12 -22 -7 -98 -39 -148 -21 -35 -48 -46 -35 -15 4 9 9
35 12 57 4 34 1 46 -22 73 -26 31 -55 43 -55 22 0 -5 5 -10 10 -10 17 0 11
-40 -25 -169 -19 -69 -35 -136 -35 -151 0 -17 13 -39 38 -63 45 -44 59 -48 36
-8 -16 27 -16 33 5 125 22 92 23 96 46 90 33 -8 78 26 104 79 27 56 26 69 -5
106 -29 35 -51 42 -35 12z"
              />
            </g>
          </svg>
        </div>
      </router-link>

      <!-- Navigation Links -->
      <nav
        :class="[
          'flex-col md:flex-row md:flex md:items-center md:space-x-6 space-y-4 md:space-y-0',
          isMenuOpen ? 'flex' : 'hidden',
        ]"
        class="mt-4 md:mt-0 flex-1 md:justify-center"
      >
        <ul class="flex flex-col md:flex-row items-center">
          <li
            v-for="link in navLinks"
            :key="link.text"
            class="md:mx-4 py-2 md:py-0"
          >
            <router-link
              :to="link.to"
              class="text-gray-500 hover:text-gray-700"
              @click="closeMenu"
            >
              {{ link.text }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="svg-container" v-if="!isMenuOpen"></div>
    </div>
  </header>
</template>

<script setup>
import { ref } from "vue";

const navLinks = ref([
  { text: "Home", to: "/" },
  { text: "RSVP", to: "/rsvp" },
  { text: "Event Details", to: "/details" },
  { text: "Wedding Party", to: "/wedding-party" },
  { text: "Travel", to: "/travel" },
  { text: "Gift Registry", to: "/registry" },
  { text: "FAQs", to: "/faq" },
]);

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};
</script>

<style scoped>
.router-link-active {
  color: white;
}
.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  max-width: 100px;
}

@media (max-width: 768px) {
  .svg-container {
    justify-content: center;
    align-items: center;
    padding: 0 0 0 0;
    margin: 0 auto;
  }

  .svg-container > svg {
    height: 100px;
  }
}

@media (min-width: 769px) {
  .svg-container {
    justify-content: center;
  }
}

/* Resizing the hamburger icon */
button svg {
  width: 24px;
  /* Standard size for mobile */
  height: 24px;
  /* Standard size for mobile */
}
</style>
